<template>
  <form
    @submit.prevent="submitProduct"
    autocomplete="off"
    class="w-full max-h-screen bg-background flex flex-col relative"
    :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <page-header title="Create Product">
      <button
        type="submit"
        class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </page-header>

    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <!-- Product code, name, description -->
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="flex justify-between mb-5 -mx-5">
          <div class="w-full flex flex-wrap">
            <div class="w-full sm:w-2/5 md:w-1/3 lg:w-1/4 px-5">
              <input
                v-model="newProduct.product_code"
                ref="tbProductCode"
                required
                class="uppercase w-full h-full block text-secondary text-xl py-1 px-4 bge-input rounded"
                placeholder="Boston Part No."
              />
            </div>
            <div class="w-full sm:w-3/5 md:w-2/3 lg:w-3/4 pl-0 px-5 mt-5 sm:mt-0">
              <input
                v-model="newProduct.name"
                required
                class="w-full h-full block text-secondary font-semibold text-2xl py-1 px-4 bge-input rounded"
                placeholder="Product name"
              />
            </div>
          </div>
        </div>

        <textarea
          v-model="newProduct.description"
          class="text-secondary text-lg leading-snug w-full focus:outline-none bge-input bge-input-spacing rounded rounded mb-3"
          placeholder="Product description"
        ></textarea>
        <div class="flex flex-wrap -mx-5">
          <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 px-5">
            <label class="uppercase font-semibold text-sm">Category</label>
            <div class="inline-block relative">
              <select v-model="newProduct.category" class="bge-input bge-select rounded">
                <option>Vehicle Lifts</option>
                <option>Brake Testing</option>
                <option>Emission Equipment</option>
                <option>Jacking Beams</option>
                <option>Headlamp Testing</option>
                <option>Diagnostics</option>
                <option>MOT Packages</option>
                <option>Spare Parts / Other</option>
                <option>Play Detectors</option>
                <option>Air Conditioning</option>
                <option>Software</option>
                <option>Wheel and Tyre</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 px-5">
            <label class="uppercase font-semibold text-sm">Type</label>
            <div class="inline-block relative">
              <select v-model="newProduct.type" class="bge-input bge-select rounded">
                <option>Equipment with Serial Number</option>
                <option>Equipment without Serial Number</option>
                <option>Spares</option>
                <option>Accessories</option>
                <option>Consumables</option>
                <option>Package</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Product code, name, description -->

      <!-- Stock Details -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">Stock Details</h3>
            <hr class="flex-1 border-primary ml-4" />
          </div>
          <div class="flex flex-wrap -my-3 -mx-5">
            <div class="w-full flex flex-col mt-3">
              <!-- <h4 class="ml-5 text-lg font-semibold">Location:</h4> -->
              <div class="flex flex-wrap">
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Warehouse</label>
                  <input
                    v-model="newProduct.location.warehouse_no"
                    class="bge-input bge-input-spacing rounded"
                    type="text"
                  />
                </div>
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Rack</label>
                  <input
                    v-model="newProduct.location.rack_no"
                    class="bge-input bge-input-spacing rounded"
                    name="rack"
                    type="text"
                  />
                </div>
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Bin</label>
                  <input
                    v-model="newProduct.location.bin_no"
                    class="bge-input bge-input-spacing rounded"
                    name="bin"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm">Reorder stock level</label>
              <input
                v-model="newProduct.reorder_level"
                class="bge-input bge-input-spacing rounded"
                type="text"
              />
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm">Quantity available</label>
              <input
                v-model="newProduct.available_qty"
                class="bge-input bge-input-spacing rounded"
                type="text"
              />
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm">Reorder quantity</label>
              <input
                v-model="newProduct.reorder_qty"
                class="bge-input bge-input-spacing rounded"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- /Stock Details -->

      <!-- Supplier Details -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">Supplier Details</h3>
            <hr class="flex-1 border-primary ml-4" />
          </div>
          <div class="flex flex-wrap -my-3 -mx-5">
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm">Supplier</label>
              <div
                class="fixed inset-0 overflow-hidden z-10"
                v-if="supplierNameSelectOpen"
                @click="supplierNameSelectOpen = false"
              ></div>
              <div class="relative">
                <div class="relative">
                  <input
                    readonly
                    @click="openSearchSupplier"
                    v-model="newProduct.supplier_info.name"
                    class="w-full bge-input bge-input-spacing rounded cursor-pointer"
                    type="text"
                  />
                  <button
                    v-if="newProduct.supplier_info.name"
                    name="Clear Supplier"
                    @click="clearSupplier"
                    class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
                  >
                    <svg
                      class="stroke-current h-5 w-5"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <path d="M18 6L6 18M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div
                  v-if="supplierNameSelectOpen"
                  class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 ml-5 p-2"
                >
                  <div class="relative flex flex-col">
                    <input
                      ref="tbSupplierSearch"
                      type="text"
                      v-model="supplierSearchTerm"
                      @keydown="searchSupplier"
                      class="bge-input bge-input-spacing rounded"
                      placeholder="Supplier name (enter 3 letters or more to search)"
                    />
                    <div class="flex flex-col overflow-y-scroll bg-white mt-2">
                      <template v-for="result in supplierResults">
                        <button
                          type="button"
                          :key="result.supplier_id"
                          @click="selectSupplier(result)"
                          class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                        >
                          <span class="ml-1">{{ result.name }}</span>
                        </button>
                      </template>
                      <p
                        class="mx-auto py-4"
                        v-if="supplierResults == ''"
                      >No suppliers match your search</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm">Supplier Part No.</label>
              <input
                v-model="newProduct.supplier_info.part_no"
                class="bge-input bge-input-spacing rounded"
                type="text"
              />
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm">Delivery Lead Time</label>
              <div
                class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
              >
                <div class="flex justify-center items-center p-2">
                  <svg
                    class="stroke-current h-5 w-5"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <path d="M12 6v6l4 2" />
                  </svg>
                </div>
                <input
                  v-model="newProduct.lead_time"
                  class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Supplier Details -->

      <!-- Pricing -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">Pricing</h3>
            <hr class="flex-1 border-primary ml-4" />
          </div>
          <div class="flex flex-wrap -mb-3 -mx-5">
            <div class="w-full flex flex-col mb-3">
              <!-- <h4 class="ml-5 text-lg font-semibold">Pricing:</h4> -->
              <div class="flex flex-wrap">
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Cost Price</label>
                  <div
                    class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
                  >
                    <div class="flex justify-center items-center p-2">
                      <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                        <path
                          d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
                        />
                      </svg>
                    </div>
                    <input
                      v-model="newProduct.cost_price"
                      class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                      type="text"
                    />
                  </div>
                </div>

                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Delivery Cost In</label>
                  <div
                    class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
                  >
                    <div class="flex justify-center items-center p-2">
                      <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                        <path
                          d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
                        />
                      </svg>
                    </div>
                    <input
                      v-model="newProduct.delivery_in"
                      class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                      type="text"
                    />
                  </div>
                </div>

                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Delivery Cost Out</label>
                  <div
                    class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
                  >
                    <div class="flex justify-center items-center p-2">
                      <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                        <path
                          d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
                        />
                      </svg>
                    </div>
                    <input
                      v-model="newProduct.delivery_out"
                      class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <div class="flex flex-wrap -mb-3 -mx-5">
            <div class="flex flex-col w-full my-3 px-5">
              <div class="w-full">
                <table class="table-auto w-full">
                  <thead>
                    <tr class="text-left border-b border-primary">
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">Band Code</th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">Description</th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">Price</th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">Delivery Price</th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(band, i) in newProduct.price_bands">
                      <tr :key="i" class="hover:bg-orange-200 even:bg-gray-100 group">
                        <td class="px-4 py-2">{{ band.band_code }}</td>
                        <td class="px-4 py-2">{{ band.description }}</td>
                        <td class="px-4 py-2">£{{ band.price.toFixed(2) }}</td>
                        <td class="px-4 py-2">
                          <span v-if="band.delivery_out">£{{ band.delivery_out.toFixed(2) }}</span>
                        </td>
                        <td class="px-4 py-2">
                          <button
                            type="button"
                            @click="removePriceBand(band)"
                            class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                          >
                            <svg
                              class="stroke-current h-5 w-5 mr-2"
                              fill="none"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                              />
                            </svg>
                            <span>Delete</span>
                          </button>
                          <button
                            type="button"
                            @click="editPriceBand(band)"
                            class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                          >
                            <svg
                              class="stroke-current h-5 w-5 mr-2"
                              fill="none"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              viewBox="0 0 24 24"
                            >
                              <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                            </svg>
                            <span>Edit</span>
                          </button>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
                <div
                  v-show="newProduct.price_bands.length == 0"
                  class="w-full text-center py-4"
                >No Price Bands Added</div>
              </div>

              <div class="flex justify-end mt-8 mb-5">
                <button
                  @click="addNewTradePrice"
                  type="button"
                  class="flex items-center bg-white text-secondary py-1 px-2 font-semibold rounded border border-secondary hover:bg-gray-200 hover:shadow active:shadow-inner transition duration-100"
                >
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 5v14M5 12h14" />
                  </svg>
                  <span>Add Price Band</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Pricing -->

      <!-- Bill of materials -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">Bill of Materials</h3>
            <hr class="flex-1 border-primary ml-4" />
          </div>
          <div class="flex flex-wrap -my-3 -mx-5">
            <div class="flex flex-col w-full my-3 px-5">
              <div class="w-full">
                <table class="table-auto w-full">
                  <thead>
                    <tr class="text-left border-b border-primary">
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">Code</th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">Name</th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">Quantity</th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, i) in newProduct.line_items">
                      <tr :key="i" class="hover:bg-orange-200 even:bg-gray-100 group">
                        <td class="px-4 py-2">{{ item.product_code }}</td>
                        <td class="px-4 py-2">{{ item.name }}</td>
                        <td class="px-4 py-2">{{ item.qty }}</td>
                        <td class="px-4 py-2">
                          <button
                            type="button"
                            @click="removeLineItem(item)"
                            class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                          >
                            <svg
                              class="stroke-current h-5 w-5 mr-2"
                              fill="none"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                              />
                            </svg>
                            <span>Delete</span>
                          </button>
                          <button
                            type="button"
                            @click="editLineItem(item)"
                            class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                          >
                            <svg
                              class="stroke-current h-5 w-5 mr-2"
                              fill="none"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              viewBox="0 0 24 24"
                            >
                              <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                            </svg>
                            <span>Edit</span>
                          </button>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
                <div
                  v-show="newProduct.line_items.length == 0"
                  class="w-full text-center py-4"
                >No Line Items Added</div>
              </div>
              <div class="flex justify-end mb-5 mt-8">
                <button
                  @click="showingAddLineItemPanel = true"
                  type="button"
                  class="flex items-center bg-white text-secondary py-1 px-2 font-semibold rounded border border-secondary hover:bg-gray-200 hover:shadow active:shadow-inner transition duration-100"
                >
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 5v14M5 12h14" />
                  </svg>
                  <span>Add Line Item</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <panel
      :showing="showingAddTradePricePanel"
      @close="showingAddTradePricePanel = false"
      :title="tradePriceTitle"
    >
      <add-edit-trade-price :tradePrice="editingTradePrice" @complete="saveTradePrice" />
    </panel>

    <panel
      :showing="showingAddLineItemPanel"
      @close="showingAddLineItemPanel = false"
      title="Add Line Item"
    >
      <add-line-item @complete="addLineItem" />
    </panel>

    <panel
      :showing="showingEditLineItemPanel"
      @close="showingEditLineItemPanel = false"
      title="Edit Line Item"
    >
      <edit-line-item :lineItem="lineItemToEdit" @complete="updateLineItem" />
    </panel>
  </form>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Notification from "@/components/Notification.vue";
import Spinner from "@/components/Spinner.vue";
import AddEditTradePrice from "@/components/Products/TradePrices/AddEditTradePrice.vue";
import AddLineItem from "@/components/Products/Create/AddLineItem.vue";
import EditLineItem from "@/components/Products/Create/EditLineItem.vue";

export default {
  name: "CreateProduct",
  components: {
    PageHeader,
    Panel,
    Spinner,
    AddEditTradePrice,
    AddLineItem,
    EditLineItem,
  },
  data() {
    return {
      isBusy: false,
      tradePriceTitle: "",
      showingAddTradePricePanel: false,
      showingAddLineItemPanel: false,
      showingEditLineItemPanel: false,
      supplierNameSelectOpen: false,
      lineItemToEdit: {},
      supplierSearchTerm: "",
      allSuppliers: [],
      supplierResults: [],
      editingTradePrice: {},
      newProduct: {
        location: {},
        supplier_info: {},
        line_items: [],
        price_bands: [],
      },
    };
  },
  async mounted() {
    this.$refs.tbProductCode.focus();

    this.allSuppliers = await this.ProductService.getSuppliers();
  },
  methods: {
    addNewTradePrice: function () {
      this.tradePriceTitle = "Add Price Band";
      this.editingTradePrice = {};
      this.showingAddTradePricePanel = true;
    },
    saveTradePrice: function (tradePrice) {
      this.showingAddTradePricePanel = false;
      let existingBand = this.$_.find(
        this.newProduct.price_bands,
        (band) => band.band_code == tradePrice.band_code
      );
      if (existingBand) {
        existingBand.price = tradePrice.price;
        existingBand.delivery_out = tradePrice.delivery_out;
      } else {
        this.newProduct.price_bands.push(tradePrice);
      }
    },
    editPriceBand: function (band) {
      this.tradePriceTitle = "Update Trade Price";
      this.editingTradePrice = {
        ...band,
      };
      this.showingAddTradePricePanel = true;
    },
    removePriceBand: function (band) {
      this.$_.remove(
        this.newProduct.price_bands,
        (band) => band.band_code == band.band_code
      );
      this.$forceUpdate();
    },
    addLineItem: function (lineItem) {
      this.showingAddLineItemPanel = false;
      this.newProduct.line_items.push(lineItem);
    },
    updateLineItem: function () {
      let item = this.$_.find(
        this.newProduct.line_items,
        (item) => item.product_code == this.lineItemToEdit.product_code
      );
      item.qty = this.lineItemToEdit.qty;
      this.lineItemToEdit = "";
      this.showingEditLineItemPanel = false;
    },
    removeLineItem: function (lineItem) {
      this.$_.remove(this.newProduct.line_items, function (i) {
        return i == lineItem;
      });
      this.$forceUpdate();
    },
    editLineItem: function (lineItem) {
      this.lineItemToEdit = { ...lineItem };
      this.showingEditLineItemPanel = true;
    },
    openSearchSupplier: function () {
      this.supplierSearchTerm = "";
      this.supplierResults = [];
      this.supplierNameSelectOpen = true;
      this.$nextTick(function () {
        this.$refs.tbSupplierSearch.focus();
      });
    },
    searchSupplier: _.debounce(function () {
      this.supplierResults = [];
      if (
        this.supplierSearchTerm != "" &&
        this.supplierSearchTerm.length >= 3
      ) {
        this.allSuppliers.forEach((s) => {
          if (
            s.name.toLowerCase().includes(this.supplierSearchTerm.toLowerCase())
          ) {
            this.supplierResults.push(s);
          }
        });
      }
    }, 500),
    selectSupplier: function (supplier) {
      this.newProduct.supplier_info.supplier_id = supplier.supplier_id;
      this.newProduct.supplier_info.name = supplier.name;
      this.supplierNameSelectOpen = false;
    },
    clearSupplier: function () {
      this.newProduct.supplier_info = {};
      this.$forceUpdate();
    },
    submitProduct: async function () {
      this.isBusy = true;
      this.newProduct.product_code = this.newProduct.product_code.toUpperCase();

      let result = new RegExp("(^[A-Z0-9-_]+$)").test(
        this.newProduct.product_code
      );

      if (!result) {
        this.isBusy = false;
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Invalid Product Code",
                  close: onClose,
                },
              },
              "The product code cannot contain spaces or '/'."
            );
          },
          {
            position: "top-right",
          }
        );
      }

      try {
        let createResult = await this.ProductService.createProduct(
          this.newProduct
        );
        for (
          let index = 0;
          index < this.newProduct.line_items.length;
          index++
        ) {
          let lineItemResult = await this.ProductService.addUpdateLineItem(
            this.newProduct.product_code,
            this.newProduct.line_items[index]
          );
        }
        this.$router.push({
          name: "product-edit",
          params: {
            productCode: createResult.product_code.toLowerCase(),
            isCreateResult: true,
          },
        });
      } catch (error) {
        if (error.status == 409) {
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "warning",
                    title: "Duplicate Product",
                    close: onClose,
                  },
                },
                "A product already exists with that product code"
              );
            },
            {
              position: "top-right",
            }
          );
        } else {
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: `Error: ${error.status}`,
                    close: onClose,
                  },
                },
                "Couldn't create product"
              );
            },
            {
              position: "top-right",
            }
          );
          console.error(error);
        }
      }

      this.isBusy = false;
    },
  },
};
</script>